<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import PlanificationsData from "./planifications-data";
import AddPlanification from "./add-planification";

import {
  layoutComputed,
  authComputed,
  planificationsMethods,
  planificationsComputed,
  provincesMethods,
  provincesComputed,
  regionsMethods,
  regionsComputed,
} from "@/state/helpers";
/**
 * Planifications component
 */
export default {
  page: {
    title: "Planifications",
    meta: [{ name: "Planifications" }],
  },
  components: {
    Layout,
    PageHeader,
    PlanificationsData,
    AddPlanification,
  },
  mounted() {
    this.retrievePlanifications({})
    .then(async ()=>{
      await this.retrieveRegions();
      await this.retrieveProvinces();
    });
    
  },
  data() {
    return {
      title: "titles.planifications.text",

      currentPage: 1,
      perPage: 10,

      busy: false,

      xlsxFile: null,

      loaderDashboard: false,

      planificationsData: [],

      provincesData: [],
      regionsFilterData: [{ value: null, text: "--All--" }],
      provincesFilterData: [{ value: null, text: "--All--" }],
      planificationsFilter: {
        regions: null,
        provinces: null,
      },
      regions: [null],
      provinces: [null],
    };
  },
  computed: {
    ...layoutComputed,
    ...authComputed,
    ...planificationsComputed,
    ...provincesComputed,
    ...regionsComputed,

    items(){
      return [
        {
          text: "Nextrack",
        },
        {
          text: this.$t("titles.planifications.text"),
          active: true,
        },
      ]
    },

    userRole(){
      return this.loggedIn.user.role
    }
  },
  methods: {
    ...planificationsMethods,
    ...provincesMethods,
    ...regionsMethods,

    retrievePlanifications({
      page, 
      perPage, 
      regions,
      provinces,
    }) {
      this.busy = true;
      return this.getPlanificationsBy({
        page: page ? page : this.currentPage,
        limit: perPage ? perPage : this.perPage,
        populate: 'province,centre', 
        sortBy: '-date,-createdAt',
        regions: regions ? regions : this.planificationsFilter.regions,
        provinces: provinces ? provinces : this.planificationsFilter.provinces,
      }).then(() => {
        this.busy = false;
      });
    },
    retrieveRegions() {
      if (this.getRegions.results)
        return this.chargeRegions(this.getRegions.results);
      else
        return this.getRegionsBy({ limit: 100 }).then((regions) => {
          return regions;
        });
    },
    retrieveProvinces(regions) {
      if (this.getProvinces.results)
        return this.chargeProvinces(this.getProvinces.results, regions);
      else
        return this.getProvincesBy({ limit: 100 }).then((provinces) => {
          return provinces;
        });
    },
    chargeRegions(regions) {
      let rData = regions;
      rData.map((obj) => {
        this.regionsFilterData.push({ value: obj.id, text: obj.name });
      });
    },
    chargeProvinces(regions) {
      this.provincesFilterData = [{ value: null, text: "--All--" }];
      let pData = this.getProvinces.results;
      pData.map((obj) => {
        if (regions.includes(obj.region))
          this.provincesFilterData.push({ value: obj.id, text: obj.name });
      });
    },
    filter() {
      this.currentPage = 1;
      this.retrievePlanifications({ ...this.planificationsFilter, page: 1 }).then(() => {
        this.hideFilter();
      });
    },
    clear() {
      this.planificationsFilter = {
        regions: null,
        provinces: null,
      };
      this.regions = [null];
      this.provinces = [null];
    },
    openAdd() {
      this.showModalAdd();
    },
    makeToast(title, msg, variant) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    showModalAdd() {
      this.$bvModal.show("add-planification-modal");
    },
    hideModalAdd() {
      this.$bvModal.hide("add-planification-modal");
    },
    add(planification) {
      this.addPlanification(planification)
        .then((planification) => {
          this.makeToast("Add planification for", planification.centre.name+" has been added", "success")
        })
        .catch(error => {
          this.makeToast("Add planification", error, "danger")
        });
    },
    upload() {
      const formData = new FormData();
      formData.append("file", this.xlsxFile);
      this.busy = true
      return this.uploadPlanifications(formData).then(() => {
        this.busy = false;
        this.currentPage = 1;
        this.retrievePlanifications({});
      });
    },
    openUpload(){
      this.$bvModal.show("upload-modal");
    },
    openFilter(){
      this.$bvModal.show("filter-modal");
    },
    hideFilter(){
      this.$bvModal.hide("filter-modal");
    },
    handlePageChange(value) {
      this.currentPage = value;
      this.retrievePlanifications({});
    },
  },
  watch: {
    getPlanifications(newVal) {
      this.planificationsData = newVal.results;
    },
    /* getProvinces(newVal) {
      this.chargeProvinces(newVal.results, this.regions);
    }, */
    getRegions(newVal) {
      this.chargeRegions(newVal.results);
    },
    regions(newVal) {
      let lenRegions = newVal.length;

      if (!newVal[lenRegions - 1] && lenRegions > 1) {
        this.regions = [null];
        this.planificationsFilter.regions = null;
      } else if (newVal[lenRegions - 1] && newVal.includes(null)) {
        var filteredRegions = this.regions.filter(function (item) {
          return item != null;
        });
        this.regions = filteredRegions;
      } else if (newVal[lenRegions - 1] && !newVal.includes(null)) {
        this.planificationsFilter.regions = newVal;
      } else if (newVal.length == 0) {
        this.regions = [null];
        this.planificationsFilter.regions = null;
      }
      this.chargeProvinces(newVal);
    },
    provinces(newVal) {
      let lenProvinces = newVal.length;

      if (!newVal[lenProvinces - 1] && lenProvinces > 1) {
        this.provinces = [null];
        this.planificationsFilter.provinces = null;
      } else if (newVal[lenProvinces - 1] && newVal.includes(null)) {
        var filteredProvinces = this.provinces.filter(function (item) {
          return item != null;
        });
        this.provinces = filteredProvinces;
      } else if (newVal[lenProvinces - 1] && !newVal.includes(null)) {
        this.planificationsFilter.provinces = newVal;
      } else if (newVal.length == 0) {
        this.provinces = [null];
        this.planificationsFilter.provinces = null;
      }
    },
  },
  beforeDestroy() {},
};
</script>

<template>
  <Layout>
    <PageHeader :title="$t(title)" :items="items" />
    <div  class="d-flex flex-row-reverse mb-2">
      <div class="text-right">
        <b-button type="button" class="mr-2" variant="light" @click="openFilter"
          ><i class="ri-filter-3-line"></i>
        </b-button>
        <b-modal 
          id="filter-modal"
          ref="filter-modal"
          hide-footer
          size="md"
          :title="this.$t('modals.filterPlanif.text')"
        >
          <div class="row">
            <div class="col-12" data-app>
              <label for="regionSh">{{ $t("modals.region.text") }}</label>
              <v-select
                id="regionSh"
                v-model="regions"
                :items="regionsFilterData"
                :menu-props="{ maxHeight: '200' }"
                item-text="text"
                item-value="value"
                multiple
              ></v-select>
            </div>
            <div class="col-12" data-app>
              <label for="provinceSh">{{ $t("modals.province.text") }}</label>
              <v-select
                id="provinceSh"
                v-model="provinces"
                :items="provincesFilterData"
                :menu-props="{ maxHeight: '200' }"
                item-text="text"
                item-value="value"
                multiple
              ></v-select>
            </div>
            <div class="col-12 text-center">
              <b-button type="button" variant="light" @click="filter"
                >{{$t("buttons.filter.text")}}
              </b-button>
            </div>
          </div>
        </b-modal>
      </div>
      <div v-if="['SYSADMIN', 'ADMIN', 'MANAGER'].includes(userRole)" class="text-right">
        <b-button type="button" class="mr-2" variant="light" @click="openUpload"
          ><i class="ri-upload-line"></i>
        </b-button>
        <b-modal 
          id="upload-modal"
          ref="upload-modal"
          hide-footer
          size="md"
        >
          <div class="row">
            <div class="col-12">
              <b-form-file
                id="xlsxFile"
                v-model="xlsxFile"
                :placeholder="$t('modals.choosePicture.text')"
                :drop-placeholder="$t('modals.dropPicture.text')"
                :browse-text="$t('modals.browse.text')"
                accept=".xlsx"
              ></b-form-file>
            </div>
            <div class="col-12 text-center">
              <b-button type="button" variant="light" @click="upload"
                >{{$t("buttons.uploadPlanifications.text")}}
              </b-button>
            </div>
          </div>
        </b-modal>
      </div>
      <div v-if="['SYSADMIN', 'ADMIN', 'MANAGER'].includes(userRole)" class="text-right">
        <b-button type="button" class="mr-2" variant="info" @click="openAdd"
          >{{$t("buttons.addPlanification.text")}}
        </b-button>
        <AddPlanification @addPlanification="add" :regions="getRegions.results" :provinces="getProvinces.results" />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <PlanificationsData v-bind:planifications="planificationsData" :busy="busy" />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="dataTables_paginate paging_simple_numbers float-right">
          <ul class="pagination pagination-rounded">
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :total-rows="getPlanifications.totalResults"
              :per-page="perPage"
              @change="handlePageChange"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
  </Layout>
</template>