<template>
  <div>
    <b-modal
      id="edit-planification-modal"
      ref="edit-planification-modal"
      size="md"
      @hidden="cancel"
      :title="$t('modals.updatePlanification.text')"
    >
      <!-- Date -->
      <div class="row">
        <div class="col-12 col-lg-12">
          <label for="date">{{$t("modals.date.text")}}</label>
          <b-form-datepicker id="date" v-model="planificationEdit.date" class="mb-2"></b-form-datepicker>
          <b-form-invalid-feedback>
            {{ err.date }}
          </b-form-invalid-feedback>
        </div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="row p-2">
          <div class="col-10 text-right p-1">
            <b-button variant="success" class="text-white" @click="edit(ok)">
              {{$t("buttons.update.text")}}
            </b-button>
          </div>
          <div class="col-2 text-center p-1">
            <b-button @click="cancel()"> {{$t("buttons.cancel.text")}} </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: ["planification"],
  created() {},
  data() {
    return {
      planificationEdit: {
        centre: null,
        province: null,
        region: null,
        date: null,
      },
      err: {
        date: null,
      },
    };
  },
  watch: {
    planification(newVal) {
      this.planificationEdit = {
        planificationId: newVal.id,
        centre: newVal.centre,
        region: newVal.region,
        province: newVal.province,
        date: newVal.date,
      };
    },
  },
  methods: {
    cancel() {
      this.clear();
    },
    clear() {
      /* this.planificationEdit = {
        planificationId: null,
        name: null,
      }; */
      this.err = {
        name: null,
      };
    },
    edit(ok) {
      if (!this.planificationEdit.date) this.err.date = this.$t("msgError.obligField.text");
      else this.err.date = "";

      if (!this.err.date) {
        const date = new Date(this.planificationEdit.date).toISOString()
        if (this.planificationEdit.date != this.planification.date) {
          this.planificationEdit = {
            ...this.planificationEdit,
            date: date
          }
          ok();
          this.$emit("editPlanification", this.planificationEdit);
        } else {
          ok();
        }
      }
    },
  },
};
</script>